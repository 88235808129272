<template>
  <section
    id="add-survey-chart"
    class="add-survey-chart vs-con-loading__container"
  >
    <div class="add-survey-chart__form">
      <!-- name -->
      <vs-input
        :label="lang.surveyCharts.create.form.name.label[languageSelected]"
        :placeholder="
          lang.surveyCharts.create.form.name.placeholder[languageSelected]
        "
        class="fill"
        v-model="newChart.name"
        name="name"
        v-validate="'required'"
        :class="{ 'is-invalid': errors.first('name') }"
      />
      <span class="error-msg" v-if="errors.first('name')">
        {{ errors.first('name') }}
      </span>

      <!-- type -->
      <div class="mt-6">
        <label for="chart-type">
          {{ lang.surveyCharts.create.form.type.label[languageSelected] }}
        </label>
        <multiselect
          id="chart-type"
          v-model="chartType"
          :options="CHART_TYPES"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="
            lang.surveyCharts.create.form.type.label[languageSelected]
          "
          name="type"
          v-validate="'required'"
          :class="{ 'is-invalid': errors.first('type') }"
        >
          <template slot="option" slot-scope="props">
            <img
              class="option__image"
              :src="require(`@/assets/images/charts/${props.option}.png`)"
              alt=""
            />
            <div class="option__desc">
              <span class="option__title">{{ props.option }}</span>
            </div>
          </template>
        </multiselect>
        <span class="error-msg" v-if="errors.first('type')">
          {{ errors.first('type') }}
        </span>
      </div>

      <!-- bot/service -->
      <div class="mt-6">
        <BotNoVersionFilter
          :allow-empty="true"
          :multiple-select="false"
          @change="onBotChange"
          ref="botFilter"
        />
      </div>

      <!-- survey -->
      <!-- TODO: refactor survey -->
      <div class="mt-6">
        <label for="chart-survey">
          {{ lang.surveyCharts.create.form.survey.label[languageSelected] }}
        </label>
        <multiselect
          id="chart-survey"
          v-model="selectedSurvey"
          :options="surveys"
          track-by="_id"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="
            lang.surveyCharts.create.form.survey.label[languageSelected]
          "
          name="survey"
          v-validate="'required'"
          :class="{ 'is-invalid': errors.first('survey') }"
          @select="onSurveySelected"
        />
        <span class="error-msg" v-if="errors.first('survey')">
          {{ errors.first('survey') }}
        </span>
      </div>

      <div
        v-if="
          chartType === 'cluster' &&
            !clustersDisclaimer &&
            selectedSurvey != null
        "
        class="mt-6"
      >
        <ul class="con-s">
          <li>
            <vs-checkbox v-model="npsFilter">{{
              lang.surveyCharts.create.form.npsfilter.name[languageSelected]
            }}</vs-checkbox>
          </li>
        </ul>
        <label v-if="npsFilter">
          {{ lang.surveyCharts.create.form.npsfilter.label[languageSelected] }}
        </label>
        <multiselect
          id="nps-filter-field"
          v-if="npsFilter"
          v-model="npsValues"
          label="name"
          :options="NPS_FILTER_VALUES"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="
            lang.surveyCharts.create.form.npsfilter.label[languageSelected]
          "
        />
      </div>

      <!-- clustered answers -->
      <div class="mt-6" v-if="clusterAnswers.length">
        <label for="chart-cluster">
          {{ lang.surveyCharts.create.form.cluster.label[languageSelected] }}
        </label>
        <multiselect
          id="chart-cluster"
          v-model="selectedClusterAnswer"
          :options="clusterAnswers"
          track-by="key"
          label="name"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="
            lang.surveyCharts.create.form.cluster.label[languageSelected]
          "
          name="cluster"
          v-validate="'required'"
          :class="{ 'is-invalid': errors.first('cluster') }"
        />
        <!-- @select="onSurveySelected" -->
        <span class="error-msg" v-if="errors.first('cluster')">
          {{ errors.first('cluster') }}
        </span>
      </div>

      <!-- cluster unavailable disclaimer -->
      <KonaAlert
        icon="AlertCircleIcon"
        bg-color="rgba(var(--vs-warning), 0.15)"
        color="rgba(var(--vs-primary), 1)"
        v-show="chartType === 'cluster' && clustersDisclaimer"
      >
        {{
          lang.surveyCharts.create.form.cluster.clusterDisclaimer[
            languageSelected
          ]
        }}
      </KonaAlert>

      <vs-divider position="left" v-if="showGroups" class="mt-6">
        {{ lang.surveyCharts.create.form.groups.title[languageSelected] }}
      </vs-divider>

      <!-- nps groups -->
      <ChartGroups
        v-if="showGroups"
        :survey-options="surveyOptions"
        @onGroupAdd="onGroupAdd"
        ref="chartGroups"
      />
      <span
        class="error-msg"
        v-if="
          showGroups &&
            newChart.groups &&
            newChart.groups.length === 0 &&
            showGroupError
        "
      >
        {{ lang.surveyCharts.create.form.groups.error[languageSelected] }}
      </span>

      <!-- created nps groups -->
      <section
        v-if="newChart.groups && newChart.groups.length"
        class="mt-4 add-survey-chart__groups"
      >
        <div
          class="group"
          v-for="(group, groupIdx) in newChart.groups"
          :key="groupIdx"
        >
          <fieldset>
            <legend>
              {{ group.name }}
              <feather-icon
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                icon="XIcon"
                class="pl-2 cursor-pointer text-danger"
                svgClasses="h-5 w-5"
                @click="removeGroup(groupIdx)"
              />
            </legend>

            <div class="pl-2">
              <vs-chip
                v-for="(groupValue, valueIdx) in group.values"
                :key="valueIdx"
                @click="removeGroupValue(groupIdx, valueIdx)"
                closable
              >
                {{ groupValue }}
              </vs-chip>
            </div>
          </fieldset>
        </div>
      </section>

      <!-- filters -->
      <section v-if="showFilters" class="mt-6">
        <vs-divider position="left" v-if="surveyOptions">
          {{ lang.surveyCharts.create.form.filters.title[languageSelected] }}
          <small class="optional-field">
            <i>
              ({{ lang.surveyCharts.create.form.optional[languageSelected] }})
            </i>
          </small>
        </vs-divider>

        <!-- filter operator -->
        <ul class="add-survey-chart__filter-type">
          <li>
            <vs-radio v-model="newChart.filterType" vs-value="and">
              AND
            </vs-radio>
          </li>
          <li>
            <vs-radio v-model="newChart.filterType" vs-value="or">
              OR
            </vs-radio>
          </li>
        </ul>

        <!-- extra filters -->
        <ExtraFilters
          class="mt-4"
          :survey-extra-options="surveyExtraOptions"
          @onExtraAdd="onExtraAdd"
        />

        <section
          v-if="newChart.filters.length"
          class="mt-4 add-survey-chart__groups"
        >
          <div
            class="group"
            v-for="(filter, filterIdx) in newChart.filters"
            :key="filterIdx"
          >
            <vs-chip @click="removeFilter(filterIdx)" closable>
              {{ filter.field }}:<i>&nbsp;{{ filter.value }}</i>
            </vs-chip>
          </div>
        </section>

        <div v-if="hasExtraField() && surveyExtraOptions" style="display: flex">
          <div class="mt-6">
            <label for="chart-extra-field">
              {{
                lang.surveyCharts.create.form.extraField.label[languageSelected]
              }}
            </label>
            <!-- v-if="surveyExtraOptions.length > 0" -->
            <multiselect
              style="flex-basis: 42.5%"
              id="chart-extra-field"
              v-model="extraField"
              :options="surveyExtraOptions"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :placeholder="
                lang.surveyCharts.create.form.extraField.placeholder[
                  languageSelected
                ]
              "
              name="extra-field"
              v-validate="'required'"
              :class="{ 'is-invalid': errors.first('extra-field') }"
            />
            <span class="error-msg" v-if="errors.first('extra-field')">
              {{ errors.first('extra-field') }}
            </span>
          </div>
        </div>
      </section>
    </div>

    <div class="mt-6 add-survey-chart__actions">
      <vs-button
        type="flat"
        class="float-left mr-4"
        color="warning"
        @click.stop="reset"
      >
        {{ lang.surveyCharts.create.form.actions.reset[languageSelected] }}
      </vs-button>
      <vs-button
        type="flat"
        class="float-left mr-4"
        color="danger"
        @click="cancel"
      >
        {{ lang.surveyCharts.create.form.actions.cancel[languageSelected] }}
      </vs-button>
      <vs-button
        color="primary"
        :disabled="chartType === 'cluster' && clustersDisclaimer"
        @click.stop="createChart"
      >
        {{ lang.surveyCharts.create.form.actions.create[languageSelected] }}
      </vs-button>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import services from '../../survey-maker/services'

const CHART_TYPES = ['pie', 'bar', 'cluster']
const CHARTS_WITH_EXTRA_FIELD = ['bar']
export default {
  name: 'AddSurveyChart',
  props: {
    value: Object,
    editChart: Object
  },
  data() {
    return {
      CHART_TYPES,
      NPS_FILTER_VALUES: [],
      surveys: [],
      npsFilter: false,
      selectedSurvey: null,
      surveyOptions: null,
      npsValues: null,
      botService: null,
      selectedBot: null,
      selectedField: null,
      selectedOptions: null,
      surveyExtraOptions: null,
      chartType: null,
      extraField: null,
      newChart: {
        name: '',
        field: '',
        groups: [],
        filterType: 'and',
        filters: [],
        surveyService: '',
        botService: ''
      },
      showGroupError: false,
      clusteredResponses: [],
      clustersDisclaimer: false,
      clusterAnswers: [],
      selectedClusterAnswer: null
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect'),
    ChartGroups: () => import('./components/ChartGroups.vue'),
    ExtraFilters: () => import('./components/ExtraFilters.vue'),
    BotNoVersionFilter: () =>
      import('../../metrics/filters/components/BotNoVersionFilter.vue'),
    KonaAlert: () => import('../../../../components/KonaAlert.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('surveyMetrics', [
      'surveyOptionsById',
      'surveyExtraOptionsById'
    ]),
    showGroups() {
      return (
        this.surveyOptions && this.chartType && this.chartType !== 'cluster'
      )
    },
    showFilters() {
      return (
        this.surveyExtraOptions &&
        this.chartType &&
        this.chachartType !== 'cluster'
      )
    }
  },
  methods: {
    ...mapActions('surveyMetrics', [
      'getSurveyGroupOptions',
      'getExtraFiltersOptions'
    ]),
    reset() {
      this.$refs.botFilter.reset()

      this.newChart = {
        name: '',
        field: '',
        groups: [],
        filterType: 'and',
        filters: [],
        surveyService: '',
        botService: ''
      }
      this.surveyOptions = null
      this.surveyExtraOptions = null
      this.botService = null
      this.selectedSurvey = null
      this.selectedField = null
      this.selectedOptions = null
      this.chartType = null
      this.extraField = null

      this.$validator.reset()
    },
    onGroupAdd(fieldAndGroup) {
      this.newChart.groups.unshift(fieldAndGroup.group)
      this.newChart.field = fieldAndGroup.field
      this.showGroupError = false
    },
    removeGroup(groupIdx) {
      this.newChart.groups.splice(groupIdx, 1)
      this.$refs.chartGroups.removeGroup(groupIdx)
    },
    removeGroupValue(groupIdx, valueIdx) {
      const value = this.newChart.groups[groupIdx].values[valueIdx]
      this.newChart.groups[groupIdx].values.splice(valueIdx, 1)
      const so = this.surveyOptions.find(s => s.key === this.newChart.field)
      if (so.options) {
        so.options.push(value)
        so.options.sort((a, b) => a - b)
      }
      if (this.newChart.groups[groupIdx].values.length === 0) {
        this.removeGroup(groupIdx)
      }
    },
    cancel() {
      this.$emit('onCancel')
      this.reset()
    },
    // isValid() {
    //   this.validations.name = !this.newChart.name
    //   this.validations.chartType = !this.newChart.chartType
    //   this.validations.surveyService = !this.newChart.surveyService
    //   // this.validations.field = !this.newChart.field

    //   const valid = new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       !Object.values(this.validations).some(elem => elem)
    //         ? resolve(true)
    //         : reject()
    //     }, 1)
    //   })

    //   return !Object.values(this.validations).some(elem => elem)
    // },
    validateGroups() {
      const validGroups =
        this.chartType !== 'cluster' ? this.newChart.groups.length > 0 : true
      this.showGroupError = !validGroups

      return validGroups
    },
    createChart() {
      // TODO: validate
      // if (!this.isValid()) return
      this.$validator
        .validate()
        .then(valid => {
          valid = valid && this.validateGroups()

          if (valid) {
            this.newChart.botService = this.botService
            // add extra field
            if (this.hasExtraField()) {
              this.newChart['extraField'] = this.extraField
            }
            if (this.chartType === 'cluster') {
              this.newChart.field = this.selectedClusterAnswer.key
              if (this.npsFilter) {
                this.newChart.npsFilter = this.npsValues.value
              } else {
                this.newChart.npsFilter = '0'
              }
              delete this.newChart.groups
              delete this.newChart.filterType
              delete this.newChart.filters
            }
            // add bot service
            this.$emit('onCreate', {
              type: this.chartType,
              data: this.newChart
            })
          } else {
            // const tabScroll = document.getElementById('add-survey-chart')
            // window.scrollTo({
            //   behavior: 'smooth',
            //   left: 0,
            //   top: tabScroll.offsetTop - 80
            // })
          }
        })
        .catch(error => {
          console.warn(error)
        })
    },
    onExtraAdd(extra) {
      this.newChart.filters.unshift(extra)
    },
    removeFilter(filterIdx) {
      this.newChart.filters.splice(filterIdx, 1)
    },
    async getSurveyOptions(surveyId) {
      this.$vs.loading({ container: '#add-survey-chart', scale: 0.5 })
      try {
        await this.getSurveyGroupOptions(surveyId)
        this.surveyOptions = this.surveyOptionsById(surveyId)
      } catch (error) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.create.messages.error.surveyOptions[
            this.languageSelected
          ],
          color: 'danger'
        })
      } finally {
        this.$vs.loading.close('#add-survey-chart > .con-vs-loading')
      }
    },
    async getExtraFilters(surveyId) {
      this.$vs.loading({ container: '#add-survey-chart', scale: 0.5 })
      try {
        await this.getExtraFiltersOptions(surveyId)
        this.surveyExtraOptions = this.surveyExtraOptionsById(surveyId)
      } catch (error) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.create.messages.error.extraFilters[
            this.languageSelected
          ],
          color: 'danger'
        })
      } finally {
        this.$vs.loading.close('#add-survey-chart > .con-vs-loading')
      }
    },
    hasExtraField() {
      return CHARTS_WITH_EXTRA_FIELD.includes(this.chartType)
    },
    async onSurveySelected(survey) {
      this.newChart.surveyService = survey.service

      await this.getSurveyOptions(survey._id)
      if (this.chartType === 'cluster') {
        this.clusterAnswers = this.surveyOptions
          .filter(option => option.clusterizeAnswers)
          .map(option => {
            return {
              name: option.name,
              key: option.key
            }
          })
        this.clustersDisclaimer = this.clusterAnswers.length === 0
      } else {
        await this.getExtraFilters(survey.service)
      }
    },
    async getSurveys() {
      try {
        const result = await services.getSurveys()
        this.surveys = result.data.map(element => ({
          _id: element._id,
          service: element.service._id,
          name: element.service.name,
          _createdAt: element._createdAt
        }))
      } catch (error) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.create.messages.error.surveys[
            this.languageSelected
          ],
          color: 'danger'
        })
      }
    },
    async initSurveys() {
      this.$vs.loading()
      try {
        await this.getSurveys()
      } catch (error) {
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.create.messages.error.surveys[
            this.languageSelected
          ],
          color: 'danger'
        })
      } finally {
        this.$vs.loading.close()
      }
    },
    async setChartToEdit(chart) {
      this.newChart = chart
      this.chartType = chart.type
      // this.botService = chart.botService
      // this.selectedBot = chart.botService
      // this.$refs.botFilter.selectBot(this.selectedBot)

      this.selectedSurvey = this.surveys.find(
        s => s.service === chart.surveyService._id
      )
      // await this.onSurveySelected(this.selectedSurvey)
    },
    onBotChange(bot) {
      this.botService = bot.value[0]
    }
  },
  mounted() {
    this.NPS_FILTER_VALUES = [
      {
        name: this.lang.surveyCharts.create.form.npsfilter.detractores[
          this.languageSelected
        ],
        value: '1'
      },
      {
        name: this.lang.surveyCharts.create.form.npsfilter.neutros[
          this.languageSelected
        ],
        value: '2'
      },
      {
        name: this.lang.surveyCharts.create.form.npsfilter.promotores[
          this.languageSelected
        ],
        value: '3'
      }
    ]
    this.initSurveys()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.vs-sidebar--background {
  z-index: 99999;
}
.add-survey-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  .vs-divider--text {
    padding-left: 0;
  }

  label {
    font-size: 0.85rem;
    padding-left: 5px;
  }

  &__form {
    .optional-field {
      color: rgba(0, 0, 0, 0.5);
      padding-left: 10px;
      font-size: 0.8rem;
    }
    .is-invalid {
      .vs-con-input input,
      .multiselect__tags {
        border: 1px solid rgba(var(--vs-danger), 1) !important;
      }
    }
    .error-msg {
      color: rgba(var(--vs-danger), 1) !important;
      font-size: 0.85rem;
      padding-left: 5px;
    }
    .vs-input--input.normal {
      min-height: 43px;
    }

    .vs-input--placeholder.normal {
      padding: 0.8rem;
    }

    .multiselect,
    .multiselect__input,
    .multiselect__single,
    .multiselect__tags,
    .multiselect__option {
      font-size: 0.85rem;
    }

    .multiselect__option {
      display: flex;
      align-items: center;
      .option__image {
        max-width: 60px;
      }
      .option__desc {
        margin-left: 10px;
      }
    }

    .multiselect__tags {
      width: 100% !important;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .multiselect__error {
      .multiselect__tags {
        border: 1px solid rgba(var(--vs-danger), 1) !important;
      }
    }
    .multiselect__message {
      color: rgba(var(--vs-danger), 1) !important;
      position: relative;
      font-size: 0.65rem;
      overflow: hidden;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      padding: 2px 4px;
      padding-bottom: 4px;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }

  .vs-con-input-label {
    width: 100%;
  }

  &__groups {
    max-height: 85px;
    overflow: auto;

    .group {
      display: inline-flex;
      margin-top: 10px;
      margin-right: 10px;

      fieldset {
        display: flex;
        flex-direction: row;
        border: 1px solid silver;
        padding: 8px;
        border-radius: 4px;
      }
      legend {
        padding: 2px;
        display: flex;
      }
    }
  }

  &__filter-type {
    display: flex;
    justify-content: center;

    li {
      margin-right: 10px;
    }
  }
}
</style>
